<template>
  <div class="channal-manage">
    <div class="header clearfix head-container">
      <el-select
        size="small"
        style="width: 130px;"
        class="filter-item"
        v-model="oprType"
        placeholder="渠道类型"
        clearable
        @change="toQuery"
      >
        <el-option
          v-for="item in owningList"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <el-select
        size="small"
        style="width: 130px;"
        class="filter-item"
        v-model="payType"
        placeholder="支付方式"
        clearable
        @change="toQuery"
      >
        <el-option
          v-for="item in payTypeList"
          :key="item.value"
          :label="item.name"
          :value="item.id"
        >
        </el-option>
      </el-select>
      <el-select
          size="small"
          style="width: 130px;"
          class="filter-item"
          v-model="status"
          placeholder="状态"
          clearable
          @change="toQuery"
      >
        <el-option
            v-for="item in statusList"
            :key="item.value"
            :label="item.name"
            :value="item.value"
        >
        </el-option>
      </el-select>
      <el-button
              size="small"
              type="primary"
              icon="el-icon-search"
              @click.native="queryHandle"
              class="filter-item"
      >搜索</el-button>
      <el-button
              type="primary"
              class="add-btn filter-item"
              size="small"
              @click.native="addHandle"
              icon="el-icon-plus"
      >新增</el-button>
    </div>
    <!--表格渲染-->
    <el-table border v-loading="loading" :data="data" size="small" stripe style="width: 100%;">
      <el-table-column prop="id" label="ID"/>
      <el-table-column prop="channelName" label="渠道名称"/>
      <el-table-column label="渠道类型">
        <template slot-scope="scope">
          <span>{{getChannalTypeText(scope.row)}}</span>
        </template>
      </el-table-column>
      <el-table-column label="支付方式">
        <template slot-scope="scope">
          <span>{{getPayTypeText(scope.row)}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="limitation" label="金额限制">

      </el-table-column>
      <el-table-column prop="payPlatformName" label="渠道商"/>
      <el-table-column label="状态">
        <template slot-scope="scope">
          <span>{{ scope.row.status == 1 ? '启用' : '禁用' }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="availableTime" label="可用时段">
      </el-table-column>
      <el-table-column prop="channelOrder" label="排序"/>
      <el-table-column label="操作" width="200px" align="center">
        <template slot-scope="scope">
          <el-button v-if="checkPermission(['ADMIN'])" type="success" @click="editHandle(scope.row)">编辑</el-button>
          <el-button type="primary"  v-if="checkPermission(['ADMIN']) && scope.row.status == 1" @click="enAbledOrDisabledHandle(scope.row,2)">禁用</el-button>
          <el-button type="primary"  v-if="checkPermission(['ADMIN']) && scope.row.status == 2 " @click="enAbledOrDisabledHandle(scope.row,1)">启用</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!--分页组件-->
    <public-pagination
            :total-page="total"
            :current-page="page"
            @pageChange="pageChange"
            @sizeChange="sizeChange"
    ></public-pagination>
    <add-or-edit-dialog
      :dialog-show="dialogShow"
      @close-dialog="dialogShow = false"
      :pay-info="currentPayInfo"
      :is-add="isAdd"
    ></add-or-edit-dialog>
  </div>
</template>

<script>
  import checkPermission from '@/utils/permission'
  import initData from '@/mixins/initData'
  import { parseTime } from '@/utils/index'
  import addOrEditDialog from '@/components/bussetup/channelManage/addOrEditDialog'
  import { editPayFee,queryPayTypeList } from '@/api/bussetup/payManage'
  export default {
    name:'channelManage',
    components: { addOrEditDialog },
    mixins: [initData],
    data() {
      return {
        delLoading: false,
        sup_this: this,
        dialogShow: false,
        isAdd: false,

        currentPayInfo:{},
        oprType: '',
        owningList:[
          {
            value:1,
            label: '入金'
          },
          {
            value:2,
            label: '出金'
          },
          {
            value:3,
            label: '出佣'
          },
        ],
        payType: '',
        payTypeList: [],
        status: 1, // 默认启用
        statusList:[
          {name: '启用',value: 1},
          {name: '禁用',value: 2},
        ],
      }
    },
    created() {
      this.$nextTick(() => {
        this.init();
        this.getPayTypeList();
      })
    },
    methods: {
      parseTime,
      checkPermission,
      getPayTypeList(){
        queryPayTypeList({
          page: 0,
        }).then((res) => {
          console.log(res);
          this.payTypeList = res.content;
        })
      },
      getPayTypeText(row){
        return row.payTypeName;
      },
      getChannalTypeText(row){
        switch (row.oprType) {
          case 1:
            return '入金';
          case 2:
            return '出金';
          case 3:
            return '出佣'
        }
      },
      getLimitText(row){
        return row.minimumLimit + '-' +  row.maximumLimit == 99999999.00 ? '不限限' : row.maximumLimit ;
      },
      beforeInit() {
        this.url = 'crm/queryPayChannels'
        this.params = {
          page: this.page,
          size: this.size,
          oprType: this.oprType,
          payType: this.payType,
        }
        if(this.status){
          this.params.status = this.status;
        }
        return true
      },
      queryHandle(){
        this.page = 0;
        this.init();
      },
      addHandle(){
        this.isAdd = true;
        this.dialogShow = true;
      },
      editHandle(row){
        this.isAdd = false;
        this.currentPayInfo = row;
        this.dialogShow = true;
      },
      enAbledOrDisabledHandle(row,status){
        editPayFee({
          id: row.id,
          status: status
        },2).then((res) => {
          this.$notify({
            title: '操作成功',
            type: 'success',
            duration: 2500
          });
          this.init();
        })
      },
    }
  }
</script>

<style lang="less" scoped>
.channal-manage {
  padding: 30px;
}
</style>
