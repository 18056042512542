import { render, staticRenderFns } from "./addOrEditDialog.vue?vue&type=template&id=6281a85e&scoped=true&"
import script from "./addOrEditDialog.vue?vue&type=script&lang=js&"
export * from "./addOrEditDialog.vue?vue&type=script&lang=js&"
import style0 from "./addOrEditDialog.vue?vue&type=style&index=0&lang=less&"
import style1 from "./addOrEditDialog.vue?vue&type=style&index=1&id=6281a85e&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6281a85e",
  null
  
)

export default component.exports